<template>
  <component :is="componentToShow"
             class="relative group overflow-hidden rounded-[5vw] md:rounded-[1.25vw] w-full h-full flex flex-col justify-between text-white"
             @click="openModal"
             :href="href"
             :target="href && href.toLowerCase().startsWith('http') ? '_blank' : ''"
  >
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="w-full h-full aspect-w-9 aspect-h-16">
      <div class="flex flex-col justify-between p-[5vw]"
           :class="{
             'md:p-[1.25vw]': type === 'default',
             'md:p-[1.5vw]': type === 'large'
           }"
      >
        <NuxtImg format="auto"
                  v-if="backgroundType === 'image'"
                  sizes="xs:62vw sm:62vw md:20vw lg:20vw xl:20vw xxl:20vw"
                  fit="cover"
                  width="307"
                  height="546"
                  loading="lazy"
                  placeholder
                  class="absolute inset-0 object-cover w-full h-full duration-200 pointer-events-none"
                  :class="{
                    'md:group-hover:scale-105': componentToShow !== 'div',
                  }"
                  :src="background"
                  alt=""
        />
        <div v-else-if="backgroundType === 'video'"
             class="absolute inset-0 object-cover w-full h-full duration-200 pointer-events-none "
        >
          <video autoplay
                 loop
                 muted
                 playsinline
                 disableremoteplayback="true"
                 class="object-cover w-full h-full"
          >
            <source :src="background"
                    type="video/mp4"
            >
          </video>
        </div>
        <div v-else
             class="absolute inset-0 object-cover w-full h-full pointer-events-none bg-red"
        />
        <!-- Play button -->
        <div v-if="componentToShow === 'button'"
             class="absolute inset-0 flex items-center justify-center"
        >
          <ButtonPlay />
        </div>
        <div class="absolute bottom-0 left-0 right-0 w-full pointer-events-none h-1/2"
             style="background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))"
        />
        <div>
          <div v-if="newTag"
               class="w-[24vw] h-[24vw] md:w-[6vw] md:h-[6vw] relative flex justify-center md:pt-[1.5vw] pt-[5.5vw] text-center md:px-[1vw] px-[5vw]"
          >
            <SvgShapeGear class="absolute inset-0 w-full h-full animate-scale text-red" />
            <span class="relative font-oswald uppercase text-[5vw] md:text-[1.2vw] font-medium leading-[1.1] tracking-[-0.01em]">Just Landed</span>
          </div>
          <div v-if="comingSoonTag"
               class="w-[24vw] h-[24vw] md:w-[6vw] md:h-[6vw] relative flex justify-center md:pt-[1.7vw] pt-[6.5vw] text-center md:px-[1vw] px-[5vw] z-10"
          >
            <SvgShapeGear class="absolute inset-0 w-full h-full animate-scale text-lemon" />
            <span class="relative font-oswald uppercase text-indigo text-[5vw] md:text-[1.2vw] font-medium leading-[1.1] tracking-[-0.01em]">Coming soon</span>
          </div>
          <div v-if="newCharacterTag"
               class="w-[27vw] h-[27vw] md:w-[7.4vw] md:h-[7.4vw] relative flex justify-center md:pt-[2.2vw] pt-[7.5vw] text-center md:px-[1vw] px-[5vw]"
          >
            <SvgShapeGear class="absolute inset-0 w-full h-full animate-scale text-red" />
            <span class="relative font-oswald uppercase text-[5vw] md:text-[1.2vw] font-medium leading-[1.1] tracking-[-0.01em]">New Character</span>
          </div>
        </div>
        <div class="relative flex items-center">
          <GameIcon v-if="icon"
                    :src="icon"
                    alt=""
                    class="w-[16vw] h-[16vw] mr-[3vw] flex-shrink-0"
                    :class="{
                      'md:mr-[0.75vw] md:w-[4vw] md:h-[4vw]': type === 'default',
                      'md:mr-[1vw] md:w-[5vw] md:h-[5vw]': type === 'large'
                    }"
          />
          <h3 v-if="title"
              class="text-white uppercase ] leading-[7vw] text-[6vw]  flex-grow font-medium font-oswald tracking-[-0.01em] line-clamp-2 text-left"
              :class="{
                'md:text-[1.5vw] md:leading-[1.75vw]': type === 'default',
                'md:text-[1.875vw] md:leading-[2.25vw]': type === 'large'
              }"
          >
            {{ title }}
          </h3>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { useState, useNuxtApp } from '#imports';
import { computed, resolveComponent } from 'vue';

/**
 * Common card component
 *
 * It has multiple types/positions.
 *
 * Its basic/default form is to display a background image, an optional title and icon.
 * It is not necessarily a link.
 *
 * The background can also be a muted video.
 *
 * The card can also act as an entry point to the TTF video player, by displaying a static poster
 * image and a play button. The video is played in a fullscren modal. The video is always played
 * using the TTF Player.
 */

type Props = {
  background?: string
  icon: string
  title: string
  type?: 'default' | 'large'
  newTag?: boolean
  href?: string
  videoPlaylistId?: string
  comingSoonTag?: boolean
  newCharacterTag?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  background: '',
  type: 'default',
  href: '',
  videoPlaylistId: '',
  newTag: false,
  comingSoonTag: false,
  newCharacterTag: false
});

const { $event } = useNuxtApp();
const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

function openModal() {
  $event('card-click', { props: { title: props.title, href: props.href, videoPlaylistId: props.videoPlaylistId } });
  if (componentToShow.value === 'button') {
    isOpen.value = true;
    playlist.value = props.videoPlaylistId;
  }
}

// computed property to determine if the background is an image or a video
const backgroundType = computed(() => {
  if (props.background.length > 0 && props.background.endsWith('.mp4')) {
    return 'video';
  }

  if (props.background.length > 0) {
    return 'image';
  }

  return false;
});

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.href && props.href.length > 0) {
    return resolveComponent('NuxtLink');
  }

  if (props.videoPlaylistId && props.videoPlaylistId.length > 0) {
    return 'button';
  }

  return 'div';
});
</script>

<style scoped>
.animate-scale {
    animation: scale 750ms ease-in-out infinite
}

/* scale animation keyframes */
@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
